
.search-panel {
  display: flex;
  flex-direction: row;
}
.map {
  flex: 2;
}
.filters {
  flex: 1;
}

#map {
   width: 100%;
   height: 100%;
   background-color: grey;
}
.gm-style-iw .gm-ui-hover-effect {display: none!important;} /* hide the stupid x button on infowindow */
.gm-style .gm-style-iw-c { padding: 2px; }
.gm-style .gm-style-iw-c button { display: none; }

a {
  color: #007bff;
}

/* from https://css-tricks.com/styling-based-on-scroll-position */
#mapcontainer {
  position: relative;
  height: 700px;
  margin: 1em;
}

.searchloc {
  font-size: 20px;
  margin: 0 20px;
}

tr:hover {
  /*background-color: #faa;*/
}

form.filters {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 30px;
}
form.filters .date {
  padding-top: 10px;
}
form.filters .price {
  text-align: center;
}
form.filters .polygon {
  display: inline-block;
}
.brselector {

}

.appreciation.up {
  color: green;
}
.appreciation.down {
  color: red;
}
.irregular {
  color: #aaa;
}

.helpcursor {
  cursor: help;
}
#warnings {
  color: red;
  margin: 10px 0;
}
#stats {
  margin-bottom: 10px;
}
#stats .stat {
  border: 1px solid grey;
  padding: 0 10px;
  margin: 0 5px;
  display: inline-block;
}
#thinking {
  position: fixed;
  z-index: 1000;
  color: white;
  padding-top: 500px;
  font-size: xxx-large;
  font-weight: bolder;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background: black;
  opacity: 50%;
  text-align: center;
}
#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}